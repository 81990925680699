jQuery(window).on("load", function() {

    // Equal height for product name in woocommerce product card
    jQuery('.facetwp-template').each(function(){  
        var highestBox = 0;
        jQuery(this).find('.o-grid.u-m-t3.u-flex-1 > .woocommerce-loop-product__link').each(function(){
            if(jQuery(this).height() > highestBox){  
                highestBox = $(this).height();
            }
        })
        jQuery(this).find('.o-grid.u-m-t3.u-flex-1 > .woocommerce-loop-product__link').height(highestBox);
    });

});