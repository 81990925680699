jQuery(window).on("load", function() {

    // breakpoints
    var $xs = 375;
    var $s = 479;
    var $m =  768;
    var $l = 1024;
    var $xl = 1440;
    var $xxl = 1900;

    // Foreground images slider
    if (!!jQuery('.js-slick--foreground').length) {
        // Full width slick sliders
        jQuery('.js-slick--foreground').slick({
            adaptiveHeight: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true
        });
    }

    // Value Props
    if (!!$('.js-slick--value-props').length) {
        $('.js-slick--value-props').slick({
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 6000
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 6000
                    }
                }
            ]
        });
    }


    // Brands
    if (!!$('.js-slick--brands').length) {
        $('.js-slick--brands').slick({
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                }
            ]
        });
    }

    // Products
    if (!!$('.js-slick--products').length) {
        $('.js-slick--products').slick({
            dots: true,
            appendDots: '.js-slick-dots--products',
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.js-slick--prev--products').click(function(e){
            e.preventDefault();
            $('.js-slick--products').slick('slickPrev');
        })
          
        $('.js-slick--next--products').click(function(e){
            e.preventDefault();
            $('.js-slick--products').slick('slickNext');
        })
    }

    // Case Studies
    if (!!$('.js-slick--case-studies').length) {
        $('.js-slick--case-studies').slick({
            dots: true,            
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            responsive: [ 
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },               
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    // Latest News
    if (!!$('.js-slick--latest-news').length) {
        $('.js-slick--latest-news').slick({
            dots: true,            
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                        // autoplay: true,
                        // autoplaySpeed: 6000
                    }
                }
            ]
        });
    }

    // Case Study Specific
    if (!!$('.js-slick--case-study-specific').length) {
        $('.js-slick--case-study-specific').slick({
            dots: true,
            appendDots: '.js-slick-dots--case-study-specific',
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0            
        });

        $('.js-slick--prev--case-study-specific').click(function(e){
            e.preventDefault();
            $('.js-slick--case-study-specific').slick('slickPrev');
        })
          
        $('.js-slick--next--case-study-specific').click(function(e){
            e.preventDefault();
            $('.js-slick--case-study-specific').slick('slickNext');
        })
    }

    // Related Products
    if (!!$('.js-slick--related-products').length) {
        $('.js-slick--related-products').slick({
            dots: true,
            appendDots: '.js-slick-dots--related-products',
            arrows: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            rows: 0,
            responsive: [
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.js-slick--prev--related-products').click(function(e){
            e.preventDefault();
            $('.js-slick--related-products').slick('slickPrev');
        })
          
        $('.js-slick--next--related-products').click(function(e){
            e.preventDefault();
            $('.js-slick--related-products').slick('slickNext');
        })
    }

});
