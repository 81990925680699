jQuery(window).on("load", function() {

    function checkSize(){

        if($(window).innerWidth() >= 1024) { 

            jQuery('.js-mega-menu').on('click', function(e){
                e.preventDefault();
                
                // menu.js does this already
                //jQuery(this).toggleClass('is-open');

                var id = $(this).attr("data-id");

                jQuery('.c-mega-menu').each(function(i, obj) {
                    if (jQuery(this).attr('data-id') == id) {
                        jQuery(this).toggleClass('is-open');
                    } else {
                        jQuery(this).removeClass('is-open');
                    }            
                });


            });

        }

    }
    checkSize();
    jQuery(window).resize(checkSize);

});