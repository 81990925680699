/** _accordion.js */
(function ($) {

    $.fn.Accordion = function (params) {
        this.each(function () {
            var _ = this, dataSettings;

            // Default Options
            _.options = {
                firstOpened: true,
                allOpened: false,
                single: true
            };

            // Extend any params sent into function
            $.extend(_.options, params);

            // Collect data attribute settings
            dataSettings = jQuery(this).data('accordion') || {};
            _.options = $.extend({}, _.options, dataSettings);

            // Load all items opened
            if (_.options.allOpened === true) {
                jQuery(_).find('[class*="item"]').each(function () {
                    jQuery(this).addClass('is-open');
                });
            }

            // Load with first item open
            if (_.options.firstOpened === true) {
                jQuery(jQuery(_).find('[class*="item"]')[0]).addClass('is-open');
            }

            // Accordion Click event
            jQuery(_).on('click', '[class*="title"]', function (event) {
                event.stopImmediatePropagation();

                // Remove other Open Classes
                if (_.options.single === true) {
                    jQuery(_).find('.is-open').removeClass('is-open');
                }

                // Set Open Class
                jQuery(this).parent().toggleClass('is-open');
            });
        });
    };
})(jQuery);

jQuery(window).on("load resize", function () {

    // Runtime Accordion
    jQuery('.js-accordion').Accordion();

    // Runtime for Filter Accordion
    // eslint-disable-next-line vars-on-top
    var openOnLoad = (document.documentElement.clientWidth > 1020) ? true : false;
    jQuery('.js-filterAccordion').Accordion({
        "allOpened": openOnLoad
    });

});
