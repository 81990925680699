// let modalTriggerOpen = document.getElementsByClassName("js-modalTrigger"),
//     //modalTriggerClose = document.getElementsByClassName("js-modalClose"),
//     modal = document.querySelectorAll('.js-modal'),
//     modalTriggerInnerClose = document.getElementsByClassName("js-modalInner");

// // open modal
// const openModal = event => {
//     let modalTitle = event.toElement.attributes["data-modal"].nodeValue; //the clicked modal and grabs the data-modal
//     modal = document.getElementsByClassName("js-modal"); //stores all modal in array

//     for (let i of modal) {
//         if(i.title == modalTitle) {
//             i.classList.add("is-open");
//         }
//     }

//     event.preventDefault();
// };

// // close modal
// const closeModal = () => {
//     let modal = document.querySelectorAll('.js-modal.is-open');
//     if (event.target.classList.contains("js-modalClose")) {
//         modal[0].classList.remove('is-open');
//     } else if (event.target.classList.contains("c-modal__content") || event.target.parentNode.classList.contains("c-modal__content")) {
//         event.preventDefault();
//     } else {
//         modal[0].classList.remove('is-open');
//     }
// };

// for (let i of modalTriggerOpen) {
//     i.addEventListener('click', openModal, false); // add listener the object/objects
// }

// // add close trigger when inner clicked
// for (let i of modalTriggerInnerClose) {
//     i.addEventListener('click', closeModal, false);
// }

/** OLD **/
function closeModal(modal) {

    var modal = jQuery(modal).parents('.js-modal');
    modal.removeClass('is-open');
}


jQuery(window).on("load", function(event) {

    // Modal setup and opening

    var modalTrigger    = jQuery('.js-modalTrigger'),
        modalClose      = jQuery('.js-modalClose');

    modalTrigger.on('click', function() {
         var self = jQuery(this),
             modalTitle = self.data('modal'),
             modal = jQuery('.js-modal[data-title="' + modalTitle + '"]');

         modal.addClass('is-open');
         event.preventDefault();
    });

    //Close button

    modalClose.on('click', function() {
         closeModal( jQuery(this) );
    });


    // Clicking outside the modal to close


    jQuery(".js-modalInner").click(function(event) {
        if(event.target == this) closeModal( jQuery(this) );
    });

});
