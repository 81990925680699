jQuery(window).on("load", function() {

    // Mobile Category toggle
    $('.c-sidebar p').on('click', function(e){
        e.preventDefault();

        $(this).closest('.c-sidebar').toggleClass("is-active");
        
    });

    // filter accordion   
    $(document).on('click', '.js-filter-accordion', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('is-open');
    });

    // MOBILE - filter menu
    $(document).on('click', '.js-filter-menu', function (e) {
        e.preventDefault();
        $('.c-filter').toggleClass('is-open');
    });

    // Clear all
    // $(document).on('click', '.js-filter-clear', function (e) {
    //     $(".js-checkboxcontainer").show();
    //     e.preventDefault();
    //     $('.c-filter input[type=checkbox]').each(function () {
    //         $(this).prop('checked', false);
    //     });
    //     $('.c-filter__selected-filters a').each(function () {
    //         $(this).remove();
    //     });
    // });

    // remove filter select clicking x
    // $(document).on('click', '.js-filter-selected', function (e) {
    //     e.preventDefault();
        
    //     $(this).remove();
    //     // Uncheck checkbox related to filter
    //     var filterName = $(this).text();
    //     $('.c-filter input[type=checkbox]').each(function () {
    //         if ($(this).is(":checked")) {
    //             var name = $(this).parent().find('.c-checkbox__label').text();
    //             if (filterName === name) {
    //                 $(this).prop('checked', false);
    //             }
    //         }
    //     });
    // });






    // Add / Remove filters through checkboxes
    // $(document).on('change', '.c-filter input[type=checkbox]', function (e) {

    //     var checkboxlabel = $(this).parent().find('.c-checkbox__label');
    //     var checkboxlabelParent = checkboxlabel.parent();
    //     var name = $(this).parent().find('.c-checkbox__label').text();
    //     if (this.checked) {
    //         // Add Selected Filter                    
    //         $('.c-filter__selected-filters').append('<a href="#0" class="c-filter__selected js-filter-selected">' + name + '</a>');
    //     }
    //     else {
    //         // Remove Selected Filter
    //         $('.c-filter__selected-filters a').each(function () {
    //             if ($(this).text() === name) {
    //                 $(this).remove();
    //             }
    //         });
    //     }
    // });



    // // MOBILE - remove disabled for apply button if any checkboxes have value
    // if (!!$('.js-filter-apply.c-btn--disabled').length) {
    //     $('.c-filter input[type=checkbox]').change(
    //         function () {
    //             if (this.checked) {
    //                 $('.js-filter-apply').removeClass('c-btn--disabled');
    //             }
    //         });
    // }

});


